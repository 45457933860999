import * as Accordion from '@radix-ui/react-accordion';
import classNames from 'classnames';
import { debounce } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { DEFAULT_PRICE_RANGE, useFilterQueryParams } from '../SearchPage.hooks';
import css from './SearchFilters.module.css';
import { IconChevronDown } from 'assets/icons';
import { Checkbox } from 'components/FieldCheckboxV2/FieldCheckbox';
import ChipSelectColorsInput from 'components/FieldChipSelectColorsInput/FieldChipSelectColorsInput';
import ChipSelectInput from 'components/FieldChipSelectInput/FieldChipSelectInput';
import { SliderInput } from 'components/FieldSliderInput/FieldSliderInput';
import { TextInput } from 'components/FieldTextInputV2/FieldTextInput';
import {
  availabilityOptions,
  conditionOptions,
  departmentOptions,
  productTypeOptions,
} from 'containers/EditListingPage/EditListingWizard/EditListingCategoryAndSize/productTypeDefinitions';
import { useGetBrandIdOptions } from 'hooks/api/listings/useGetBrandIdOptions';

export const SearchFilters: React.FC<{ className?: string }> = ({ className }) => {
  const filters = [
    {
      label: 'Availability',
      content: <AvailablilityFilter />,
    },
    {
      label: 'Brand',
      content: <BrandsFilter />,
    },
    {
      label: 'Shop for',
      content: <DepartmentsFilter />,
    },
    {
      label: 'Product Type',
      content: <ProductTypeFilter />,
    },
    {
      label: 'Condition',
      content: <ConditionFilter />,
    },
    {
      label: 'Color',
      content: <ColorFilter />,
    },
    {
      label: 'Size',
      content: <SizeFilter />,
    },
    {
      label: 'Price',
      content: <PriceFilter />,
    },
  ];

  return (
    <Accordion.Root type="multiple" className={classNames(css.root, className)}>
      {filters.map(filter => (
        <Accordion.Item value={filter.label} key={filter.label}>
          <Accordion.Header>
            <Accordion.Trigger className={css.accordionItem__header}>
              <span>{filter.label}</span>
              <IconChevronDown />
            </Accordion.Trigger>
          </Accordion.Header>
          <Accordion.Content className={css.accordionItem__content}>
            {filter.content}
            <div style={{ height: 1, background: '#CED2D9', marginTop: 32 }} />
          </Accordion.Content>
        </Accordion.Item>
      ))}
    </Accordion.Root>
  );
};

const SizeFilter: React.FC = () => {
  const { data: config } = useGetBrandIdOptions();
  const sizeOptions = config.enums.sizes;
  const [searchParams, setParams] = useFilterQueryParams();

  const handleSizeChange = (value: string) => {
    const sizesAsString = searchParams.sizes.map(size => String(size));
    const valueAsString = String(value);

    const newSizeArray = sizesAsString
      ? sizesAsString.includes(valueAsString)
        ? sizesAsString.filter(size => size !== valueAsString)
        : [...sizesAsString, valueAsString]
      : [valueAsString];

    setParams({ ...searchParams, sizes: newSizeArray });
  };

  return (
    <div className={css.sizeFilter}>
      {sizeOptions.map(option => (
        <Checkbox
          key={option.id}
          label={option.name}
          checked={searchParams.sizes.map(size => String(size)).includes(option.name)}
          onCheckedChange={() => handleSizeChange(option.name)}
        />
      ))}
    </div>
  );
};

const BrandsFilter: React.FC = () => {
  const [search, setSearch] = React.useState('');

  const { data: brandData } = useGetBrandIdOptions();
  const [{ brandIds = [] }, setParams] = useFilterQueryParams();

  const brandOptions = useMemo(
    () => brandData?.brands?.map(({ id, name }) => ({ value: id, label: name })) || [],
    [brandData]
  );
  const filteredOptions = brandOptions
    .filter(option => option.label.toLowerCase().includes(search.toLowerCase()))
    .slice(0, 20);
  const remaining = brandOptions.length - filteredOptions.length;

  console.log({ filteredOptions });

  return (
    <div className={css.productTypeFilter}>
      <TextInput
        className={css.productTypeFilter__search}
        label="Search"
        value={search}
        onChange={e => setSearch(e.target.value)}
      />
      {filteredOptions.map(option => (
        <Checkbox
          label={option.label}
          key={option.value}
          checked={brandIds.includes(option.value)}
          onCheckedChange={() => {
            setParams({
              brandIds: brandIds.includes(option.value)
                ? brandIds.filter(v => v !== option.value)
                : [...brandIds, option.value],
            });
          }}
        />
      ))}
      {Boolean(remaining) && (
        <div
          style={{ fontSize: 'var(--n-font-size-00)', marginTop: 'var(--n-size-1)' }}
        >{`+ ${remaining} more`}</div>
      )}
    </div>
  );
};

const DepartmentsFilter: React.FC = () => {
  const [params, setParams] = useFilterQueryParams();

  return (
    <div>
      <ChipSelectInput
        options={{ enumOptions: departmentOptions }}
        onChange={value => setParams({ department: value })}
        value={params.department}
      />
    </div>
  );
};

const AvailablilityFilter: React.FC = () => {
  const [filterParams, setParams] = useFilterQueryParams();

  const handleAvailabilityChange = selectedAvailability => {
    const newParams = {
      ...filterParams,
      availability: selectedAvailability,
    };

    setParams(newParams);
  };

  return (
    <div>
      <ChipSelectInput
        options={{ enumOptions: availabilityOptions }}
        onChange={handleAvailabilityChange}
        value={filterParams.availability}
      />
    </div>
  );
};

const ConditionFilter: React.FC = () => {
  const [params, setParams] = useFilterQueryParams();

  return (
    <div>
      <ChipSelectInput
        options={{ enumOptions: conditionOptions }}
        onChange={value => setParams({ condition: value })}
        value={params.condition}
      />
    </div>
  );
};

const ProductTypeFilter: React.FC = () => {
  const [search, setSearch] = React.useState('');

  const [params, setParams] = useFilterQueryParams();
  const productTypeValues = params.productType;

  const filteredOptions = productTypeOptions.filter(
    option =>
      productTypeValues.includes(option.value) ||
      option.label.toLowerCase().includes(search.toLowerCase())
  );

  return (
    <div className={css.productTypeFilter}>
      <TextInput
        className={css.productTypeFilter__search}
        label="Search"
        value={search}
        onChange={e => setSearch(e.target.value)}
      />
      {filteredOptions.map(option => (
        <Checkbox
          label={option.label}
          key={option.value}
          checked={productTypeValues.includes(option.value)}
          onCheckedChange={() => {
            setParams({
              productType: productTypeValues.includes(option.value)
                ? productTypeValues.filter(v => v !== option.value)
                : [...productTypeValues, option.value],
            });
          }}
        />
      ))}
    </div>
  );
};

const ColorFilter: React.FC = () => {
  const [params, setParams] = useFilterQueryParams();
  const colorValues = typeof params.colors === 'string' ? [params.colors] : params.colors;

  return (
    <ChipSelectColorsInput multiple value={colorValues} onChange={v => setParams({ colors: v })} />
  );
};

const [PRICE_FILTER_MIN, PRICE_FILTER_MAX] = DEFAULT_PRICE_RANGE;
const PRICE_FILTER_STEP = 10;

const PriceFilter: React.FC = () => {
  const [{ priceRange }, setParams] = useFilterQueryParams();
  const [localPriceRange, setLocalPriceRange] = React.useState<[number, number]>(priceRange);
  const [from, to] = localPriceRange;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSetParams = useCallback(debounce(setParams, 500), [setParams]);

  useEffect(() => {
    setLocalPriceRange(priceRange);
  }, [priceRange]);

  useEffect(() => {
    debouncedSetParams({ priceRange: localPriceRange });

    return () => debouncedSetParams.cancel();
  }, [debouncedSetParams, localPriceRange, priceRange, setParams]);

  return (
    <div className={css.priceFilter}>
      <SliderInput
        value={localPriceRange}
        onValueChange={(value: [number, number]) => setLocalPriceRange(value)}
        defaultValue={priceRange}
        step={PRICE_FILTER_STEP}
        min={PRICE_FILTER_MIN}
        max={PRICE_FILTER_MAX}
      />
      <div className={css.priceFilter__numberInputs}>
        <label>
          From
          <input
            type="number"
            min={PRICE_FILTER_MIN}
            step={PRICE_FILTER_STEP}
            value={from}
            onChange={e => setLocalPriceRange([Number(e.target.value), to])}
          />
        </label>
        <label style={{ textAlign: 'right' }}>
          To
          <input
            max={PRICE_FILTER_MAX}
            style={{ textAlign: 'right' }}
            type="number"
            step={PRICE_FILTER_STEP}
            value={to}
            onChange={e => setLocalPriceRange([from, Number(e.target.value)])}
          />
        </label>
      </div>
    </div>
  );
};
